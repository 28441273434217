import { CommonModel, IModel } from '@/models/Model'
import { allPaymentMethodTypes, PaymentMethodType } from '@/models/payments/constants'
import { CompanyBankAccount, ICompanyBankAccount } from '@/models/payments/CompanyBankAccount'
import {
  CompanyPaymentService,
  ICompanyPaymentService,
} from '@/models/payments/CompanyPaymentService'
import { Company } from '@/models/payments/Company'
import { MainStore } from '@/store/MainStore'
import { paymentsApi } from '@/api/payments-api'
import { PersonSimple } from '@/models/persons/PersonSimple'

export interface IReceiver extends IModel {
  id: number
  type: PaymentMethodType
  companyID: number
  relatedID: number
  isMain: boolean
  isActive: boolean
  companyBankAccount: ICompanyBankAccount | null
  companyPaymentService: ICompanyPaymentService | null
}

export class Receiver extends CommonModel implements IReceiver {
  id = 0
  type: PaymentMethodType = 0
  companyID = 0
  relatedID = 0
  isMain = false
  isActive = true

  companyBankAccount: CompanyBankAccount | null = null
  companyPaymentService: CompanyPaymentService | null = null

  // after load
  company: Company | null = null
  partner: PersonSimple | null = null

  get isPartner(): boolean {
    return this.type == PaymentMethodType.Partner
  }

  get isMethodBank(): boolean {
    return this.type == PaymentMethodType.SEPA
  }

  get isNumberRequired(): boolean {
    return this.type == PaymentMethodType.Card || this.type == PaymentMethodType.BankTransfer
  }

  get isMethodService(): boolean {
    return this.type == PaymentMethodType.Card || this.type == PaymentMethodType.Online
  }

  get forManualPayment(): boolean {
    return this.type != PaymentMethodType.SEPA && this.type != PaymentMethodType.Online
  }

  get typeHtml(): string {
    return allPaymentMethodTypes[this.type]
  }

  get relatedHtml(): string {
    if (this.companyBankAccount) {
      return this.companyBankAccount.titleHtml
    } else if (this.companyPaymentService) {
      return this.companyPaymentService.titleHtml
    } else if (this.partner) {
      return this.partner.fullName
    }

    return ''
  }

  get relatedShortName(): string {
    if (this.companyBankAccount) {
      return this.companyBankAccount.name
    } else if (this.companyPaymentService) {
      return this.companyPaymentService.name
    } else if (this.partner) {
      return this.partner.fullName
    }

    return ''
  }

  get titleWithCompanyHtml(): string {
    return this.companyName ? `${this.companyName}, ${this.shortName}` : this.shortName
  }

  get titleHtml(): string {
    const s = `<b>${this.typeHtml}</b>, ${this.relatedHtml}`

    return this.isActive ? s : `<s>${s}</s>`
  }

  get shortName(): string {
    return `${this.typeHtml}, ${this.relatedShortName}`
  }

  get shortNameWithCompany(): string {
    return `${this.companyName}: ${this.shortName}`.replace(/[ ,:]+$/, '')
  }

  get companyName(): string {
    return this.company ? this.company.shortName : ''
  }

  constructor(src: IReceiver | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IReceiver) {
    Object.assign(this, src)
    this.companyBankAccount = src.companyBankAccount
      ? new CompanyBankAccount(src.companyBankAccount)
      : null
    this.companyPaymentService = src.companyPaymentService
      ? new CompanyPaymentService(src.companyPaymentService)
      : null

    this.initModel(src)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await paymentsApi.receiver.add(this.forStorage().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await paymentsApi.receiver.update(this.forStorage().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  forStorage(): Receiver {
    if (this.companyBankAccount) {
      this.companyBankAccount.companyID = this.companyID
    }
    if (this.companyPaymentService) {
      this.companyPaymentService.companyID = this.companyID
    }
    this.relatedID = this.partner ? this.partner.id : this.relatedID

    return this
  }

  toInterface(): IReceiver {
    return {
      id: this.id,
      type: this.type,
      companyID: this.companyID,
      relatedID: this.relatedID,
      isMain: this.isMain,
      isActive: this.isActive,
      companyBankAccount: this.companyBankAccount ? this.companyBankAccount.toInterface() : null,
      companyPaymentService: this.companyPaymentService
        ? this.companyPaymentService.toInterface()
        : null,
    }
  }
}
