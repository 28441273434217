import { dateString, formatDate, formatDateTime } from '@/utils'
import { Money } from '@/models/Money'
import { CommonModel, IModel } from '@/models/Model'
import { PersonSimple } from '@/models/persons/PersonSimple'
import { IPaymentLine, PaymentLine } from '@/models/payments/PaymentLine'
import {
  CurrencyType,
  currencyTypes,
  PaymentStatus,
  paymentStatuses,
} from '@/models/payments/constants'
import { Discount } from '@/models/discounts/Discount'
import { IPersonPaymentMethod, PersonPaymentMethod } from '@/models/payments/PersonPaymentMethod'
import { IReceiver, Receiver } from '@/models/payments/Receiver'
import { MainStore } from '@/store/MainStore'
import { paymentsApi } from '@/api/payments-api'
import { Contract, formatContractID } from '@/models/persons/Contract'
import { contractToPersonRedirectHtml } from '@/models/persons/views'
import { BankFail, IBankFail } from '@/models/payments/BankFail'
import { IAdditionalCharge } from '@/models/subscription/AdditionalCharge'
import { CAN_CANCEL_PAYMENTS } from '@/permissions/core_server'
import { Price } from '@/models/tariffs/Price'
import { Company } from '@/models/payments/Company'

export interface IPayment extends IModel {
  id: number
  personID: number
  companyID: number
  contractID: number
  receiverID: number
  personPaymentMethodID: number
  bankFailID: number | null
  bankFailBy: number
  subtotal: number
  vat: number
  status: PaymentStatus
  transactionNumber: string
  vatPercentage: number
  currency: CurrencyType
  notes: string
  dueDate: Date | null
  paidAt: Date | null
  createdAt: Date | null
  createdBy: number
  lines: IPaymentLine[]
  receiver: IReceiver | null
  personPaymentMethod: IPersonPaymentMethod | null
  bankFail: IBankFail | null

  mainDiscount: number
}

export class Payment extends CommonModel implements IPayment {
  id = 0
  personID = 0
  companyID = 0
  contractID = 0
  receiverID = 0
  personPaymentMethodID = 0
  bankFailID: number | null = null
  bankFailBy = 0
  subtotal = 0
  vat = 0
  status: PaymentStatus = 0
  transactionNumber = ''
  vatPercentage = 0
  currency: CurrencyType = 0
  notes = ''
  dueDate: Date | null = null
  paidAt: Date | null = null
  createdAt: Date | null = null
  createdBy = 0
  lines: PaymentLine[] = []
  receiver: Receiver | null = null
  personPaymentMethod: PersonPaymentMethod | null = null
  bankFail: BankFail | null = null
  mainDiscount = 0

  // after load
  person: PersonSimple | null = null
  discount: Discount | null = null
  whoCreated: PersonSimple | null = null
  whoBankFail: PersonSimple | null = null
  company: Company | null = null
  contract: Contract | null = null

  // for Form
  createdAtForm = ''
  paidAtForm = ''
  moneySubtotal: Money = new Money()
  moneyVAT: Money = new Money()
  moneyMainDiscount: Money = new Money()

  get createdAtStr(): string {
    return formatDateTime(this.createdAt)
  }

  get paidAtStr(): string {
    return formatDateTime(this.paidAt)
  }

  get dueDateStr(): string {
    return formatDate(this.dueDate)
  }

  get statusStr(): string {
    return paymentStatuses[this.status]
  }

  get formattedContractID(): string {
    return formatContractID(this.contractID)
  }

  get paymentMethodHtml(): string {
    return this.receiver ? this.receiver.shortNameWithCompany : ''
  }

  get statusHtml(): string {
    let textColorClass = 'font-weight-bold '
    switch (this.status) {
      case PaymentStatus.Paid:
        textColorClass += 'text-success'
        break
      case PaymentStatus.Unpaid:
        textColorClass += 'text-danger'
        break
      case PaymentStatus.Refund:
        textColorClass += 'text-orangered'
        break
      case PaymentStatus.Bounce:
        textColorClass += 'text-violet'
        break
    }

    return `<span class="${textColorClass}">${this.statusStr}</span>`
  }

  get currencyName(): string {
    return currencyTypes[this.currency]
  }

  get personFullName(): string {
    return this.person ? this.person.fullName : ''
  }

  get bankFailByName(): string {
    return this.whoBankFail ? this.whoBankFail.fullName : ''
  }

  get totalDiscount(): Money {
    return new Money(this.lines.reduce((acc, line) => acc + line.discountValue, 0))
  }

  get totalUnitPrice(): Money {
    return Money.withValue(this.lines.reduce((acc, line) => acc + line.totalUnitPrice.value, 0))
  }

  get standbyCosts(): Money {
    return Money.withValue(this.lines.reduce((acc, line) => acc + line.moneyStandbyCost.value, 0))
  }

  get receiverHtml(): string {
    return this.receiver ? this.receiver.companyName : ''
  }

  get whoCreatedName(): string {
    return this.whoCreated ? this.whoCreated.fullName : ''
  }

  get contractToPersonRedirectHtml(): string {
    return contractToPersonRedirectHtml(this.contractID)
  }

  get isAllowedBounce(): boolean {
    return this.status == PaymentStatus.Unpaid
  }

  get canBeCanceled(): boolean {
    return (
      (this.status == PaymentStatus.Unpaid || this.status == PaymentStatus.Bounce) &&
      MainStore.isUserCan(CAN_CANCEL_PAYMENTS)
    )
  }

  get prices(): Price[] {
    const res: Price[] = []
    this.lines.forEach((v) => {
      v.price && res.push(v.price)
    })

    return res
  }

  get companyName(): string {
    return this.company ? this.company.name : ''
  }

  constructor(src: IPayment | Payment | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IPayment | Payment) {
    Object.assign(this, src)
    this.createdAt = this.dateOrNull(src.createdAt)
    this.paidAt = this.dateOrNull(src.paidAt)
    this.receiver = src.receiver ? new Receiver(src.receiver) : null
    this.bankFail = src.bankFail ? new BankFail(src.bankFail) : null
    this.personPaymentMethod = src.personPaymentMethod
      ? new PersonPaymentMethod(src.personPaymentMethod)
      : null

    this.moneySubtotal = new Money(src.subtotal).withVatPercentage(this.vatPercentage)
    this.moneyVAT = new Money(src.vat).withVatPercentage(this.vatPercentage)
    this.moneyMainDiscount = new Money(src.mainDiscount).withVatPercentage(this.vatPercentage)

    if (src instanceof Payment) {
      this.lines = src.lines ? [...src.lines] : []
      this.createdAtForm = src.createdAtForm
      this.paidAtForm = src.paidAtForm
    } else {
      this.lines = src.lines ? src.lines.map((v) => new PaymentLine(v)) : []
      this.createdAtForm = dateString(this.createdAt)
      this.paidAtForm = dateString(this.paidAt)
    }

    this.lines.forEach((v) => v.setVatPercentage(this.vatPercentage))

    this.initModel(src)
  }

  async refund(): Promise<boolean> {
    try {
      this.init(await paymentsApi.payment.refund({ id: this.id }))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async bounced(): Promise<boolean> {
    if (!confirm(`Do you want to bounce this payment?`)) {
      return false
    }

    try {
      this.init(await paymentsApi.payment.bounced({ id: this.id }))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await paymentsApi.payment.update(this.prepare().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async failed(charge?: IAdditionalCharge): Promise<boolean> {
    try {
      this.init(
        await paymentsApi.payment.failed({ payment: this.prepare().toInterface(), charge: charge })
      )
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  prepare(): Payment {
    this.bankFailID = this.bankFail ? this.bankFail.id : null

    return this
  }

  toInterface(): IPayment {
    return {
      id: this.id,
      personID: this.personID,
      companyID: this.companyID,
      contractID: this.contractID,
      receiverID: this.receiverID,
      personPaymentMethodID: this.personPaymentMethodID,
      bankFailID: this.bankFailID,
      bankFailBy: this.bankFailBy,
      subtotal: this.subtotal,
      vat: this.vat,
      status: this.status,
      transactionNumber: this.transactionNumber,
      vatPercentage: this.vatPercentage,
      currency: this.currency,
      notes: this.notes,
      dueDate: this.dueDate,
      paidAt: this.paidAt,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      lines: [],
      receiver: null,
      personPaymentMethod: null,
      bankFail: null,
      mainDiscount: 0,
    }
  }
}
