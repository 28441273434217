import API, { MAX_LIMIT } from '@/api/api'
import { AuthRequest } from '@/requests/AuthRequest'
import { IUser } from '@/models/user/user'
import { IRole } from '@/models/user/role'
import { ISettings } from '@/models/Settings'
import { List } from '@/models/List'
import { ObjectParams } from '@/models/types'
import { IdentityRequest } from '@/requests/IdentityRequest'

const URL_USER_MODULE = '/users'

const URL_USERS = URL_USER_MODULE
const URL_ROLES = URL_USER_MODULE + '/roles'
const URL_SETTINGS = URL_USER_MODULE + '/settings'

const URL_AUTH = URL_USERS + '/auth'
const URL_LOGIN = URL_AUTH + '/login'
const URL_RE_LOGIN = URL_AUTH + '/re-login'
const URL_LOGIN_AS_USER = URL_AUTH + '/login-as-user'
const URL_LOGIN_PORTAL = URL_AUTH + '/login-portal'
const URL_PASSWORD = URL_AUTH + '/password'

export const usersApi = {
  user: {
    getByID: async function (id: number): Promise<IUser> {
      const res = await API.get(API.buildURL(URL_USERS + '/' + id))
      return (await res.json()) as Promise<IUser>
    },

    list: async function (params: ObjectParams): Promise<List<IUser>> {
      const res = await API.get(API.buildURL(URL_USERS, { ...params }))
      return (await res.json()) as Promise<List<IUser>>
    },

    update: async function (user: IUser): Promise<IUser> {
      const res = await API.put(API.buildURL(URL_USERS), user)
      return (await res.json()) as Promise<IUser>
    },
  },

  auth: {
    createUser: async function (auth: AuthRequest): Promise<IUser> {
      const res = await API.post(API.buildURL(URL_AUTH), auth)
      return (await res.json()) as Promise<IUser>
    },

    updateUser: async function (auth: AuthRequest): Promise<IUser> {
      const res = await API.put(API.buildURL(URL_AUTH), auth)
      return (await res.json()) as Promise<IUser>
    },

    login: async function (auth: AuthRequest): Promise<IUser> {
      const res = await API.post(API.buildURL(URL_LOGIN), auth)
      return (await res.json()) as Promise<IUser>
    },

    loginAsUser: async function (r: IdentityRequest): Promise<IUser> {
      const res = await API.post(API.buildURL(URL_LOGIN_AS_USER), r)
      return (await res.json()) as Promise<IUser>
    },

    loginPortal: async function (r: IdentityRequest): Promise<string> {
      const token = await API.post(API.buildURL(URL_LOGIN_PORTAL), r)
      return await token.json()
    },

    logout: async function (): Promise<void> {
      await API.delete(API.buildURL(URL_LOGIN))
    },

    reLogin: async function (): Promise<IUser> {
      const res = await API.post(API.buildURL(URL_RE_LOGIN), {})
      return await res.json()
    },

    changePassword: async function (auth: AuthRequest): Promise<void> {
      await API.put(API.buildURL(URL_PASSWORD), auth)
    },
  },

  role: {
    getByID: async function (id: number): Promise<IRole> {
      const res = await API.get(API.buildURL(URL_ROLES + '/' + id))
      return (await res.json()) as Promise<IRole>
    },

    list: async function (params: ObjectParams): Promise<List<IRole>> {
      const res = await API.get(API.buildURL(URL_ROLES, { ...params }))
      return (await res.json()) as Promise<List<IRole>>
    },

    all: async function (): Promise<IRole[]> {
      return (await this.list({ limit: MAX_LIMIT })).items
    },

    add: async function (role: IRole): Promise<IRole> {
      const res = await API.post(API.buildURL(URL_ROLES), role)
      return (await res.json()) as Promise<IRole>
    },

    update: async function (role: IRole): Promise<IRole> {
      const res = await API.put(API.buildURL(URL_ROLES), role)
      return (await res.json()) as Promise<IRole>
    },

    delete: async function (id: number): Promise<void> {
      await API.delete(API.buildURL(URL_ROLES + '/' + id))
    },
  },

  settings: {
    getSystem: async function (): Promise<ISettings> {
      const res = await API.get(API.buildURL(URL_SETTINGS))
      return (await res.json()) as Promise<ISettings>
    },
  },
}
